import { createMuiTheme, createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[500],
    },
    secondary: {
      main: 'rgba(30,108,147,1)',
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    container: {
      padding: theme.spacing(4),
    },
    grid: {
      textAlign: 'center',
    },
    button: {
      width: '100%',
      maxWidth: '350px',
    },
  }),
);

function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Handy Menu
            </Typography>
          </Toolbar>
        </AppBar>
        <Container className={classes.container}>
          <Grid container spacing={5}>
            <Grid item xs={12} className={classes.grid}>
              <Button variant="contained" color="secondary" disableElevation className={classes.button} onClick={() => { window.location.href = 'https://core.owndays.net/Handy/HandyEntry'; }}>
                入出庫・棚卸用アプリ
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.grid}>
              <Button variant="contained" color="secondary" disableElevation className={classes.button} onClick={() => { window.location.href = 'http://192.168.1.6:8080/owndays/owndaysHandyMenu.aspx'; }}>
                POSアプリ(ミニサーバー)
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.grid}>
              <Button variant="contained" color="secondary" disableElevation className={classes.button} onClick={() => { window.location.href = 'http://192.168.1.201:8080/owndays/owndaysHandyMenu.aspx'; }}>
                POSアプリ(DT)
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.grid}>
              <Button variant="contained" color="secondary" disableElevation className={classes.button} onClick={() => { window.location.href = 'http://owpos.hanbai-net.com/owndays/owndaysHandyMenu.aspx'; }}>
                POSアプリ(クラウド)
              </Button>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default App;
